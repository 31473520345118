import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';
import Toast from '../Components/Toast';

const initialState = {
	data: [],
	formErrors: {}
};

export const getHoldersNumberBalance = createAsyncThunk(
	'holders/fetchHoldersNumberBalance',
	async (params: string, {rejectWithValue}) => {
		const state = params.replace(/[^\d]/g, '');
		try {
			const data = await SERVICES.HOLDERSNUBERBALANCE.getHoldersNumberBalance(state);
			return data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const topUpHoldersBalance = createAsyncThunk(
	'holders/topUpHoldersBalance',
	async (params: any, {rejectWithValue}) => {
		try {
			const data = await SERVICES.HOLDERSNUBERBALANCE.topUpHoldersBalance(params);
			return data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

const holdersBalanceSlice = createSlice({
	name: 'holders',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder

			.addCase(getHoldersNumberBalance.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getHoldersNumberBalance.rejected, (state: any, action: any) => {
				if (action?.payload?.message === 'The requested resource was not found.') {
					state.formErrors = t(`messages.code.${action.payload.code}`);
					Toast({type: 'error', description: t('pages.home.ticketCard')});
				}
			});
	}
});
export const {cleanFormErrors} = holdersBalanceSlice.actions;
export default holdersBalanceSlice.reducer;
