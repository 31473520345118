import {Button, Form, Modal} from 'antd';
import {FormInstance} from 'antd/lib';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {validateMessages} from '../../../../../../Components/AuthModalForm';
import FormGroup from '../../../../../../Components/formItems/FormGroup';
import {ModalFormFieldTypesFunc} from '../../../../../../utils/types';
import {getKeyByLang} from '../../../../../../utils/heplerFuncs/functions';
const changeTariffFields: ModalFormFieldTypesFunc = (t, {oldTariff, oldTariffId}) => [
	{
		name: 'oldTariff_nottoback',
		label: t('pages.account.ticketHoldersPage.actions.changeTariff.popup.oldTariff'),
		type: 'text',
		disabled: true,
		style: {
			height: 68
		},
		inputProps: {
			defaultValue: oldTariff
		},
		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		}
	},
	{
		name: 'newTariffId',
		label: t('pages.account.ticketHoldersPage.actions.changeTariff.popup.selectNewTariff'),
		type: 'select',
		style: {
			height: 68
		},
		colProps: {
			lg: 24,
			xs: 24
		},
		hiddenOptions: [oldTariffId],
		customApiFetch: {
			path: 'api/v1/tariffs/public?holderTypeId=2',
			labelKey: getKeyByLang('name'),
			valueKey: val => {
				let tariffData = {
					id: val.id,
					price: val.price.slice(0, -3),
					step: val.topUpAmountStep,
					typeId: val?.typeId
				};
				return JSON.stringify(tariffData);
			}
		}
	}
];
export const ChangeTariffModal = ({
	onSubmitChangeTariff,
	changeTariffForm,
	formErrorsChangeTariff,
	oldTariff,
	holderNumber,
	loading,
	popupAction,
	oldTariffId,
	balance,
	typeId
}: {
	onSubmitChangeTariff: any;
	changeTariffForm: FormInstance<any>;
	formErrorsChangeTariff: string[];
	oldTariff: string;
	holderNumber: string;
	loading: boolean;
	popupAction: any;
	oldTariffId: string | number;
	balance: string;
	typeId: string | number;
}) => {
	let {t} = useTranslation();
	const [warningPopup, setWarningPopup] = useState(false);
	const onSubmit = (data: any) => {
		let parsedData = JSON.parse(data.newTariffId);
		let sendData = {
			providerId: 2,
			amount: !!parsedData.step && typeId === 3 ? parsedData.step : parsedData.price,
			holderNumber: holderNumber,
			newTariffId: parsedData.id
		};
		changeTariffForm.validateFields().then(() => {
			onSubmitChangeTariff(sendData)
				.then((res: any) => setWarningPopup(false))
				.catch((err: any) => console.log(err));
		});
	};

	const handleChangeTariff = () => {
		if (!!+balance) {
			setWarningPopup(true);
		} else {
			changeTariffForm.submit();
		}
	};
	return (
		<>
			<Form
				form={changeTariffForm}
				layout="vertical"
				onFinish={onSubmit}
				validateTrigger="onBlur"
				validateMessages={validateMessages(t)}
				onValuesChange={() => {}}>
				<FormGroup
					tabFields={changeTariffFields(t, {oldTariff, oldTariffId})}
					formErrors={formErrorsChangeTariff}
					form={changeTariffForm}
				/>
			</Form>
			<div className="main-buttons">
				<Button
					onClick={() => {
						popupAction('changeTariff', false);
						changeTariffForm.resetFields();
					}}>
					{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.cancelText')}
				</Button>
				<Button onClick={() => handleChangeTariff()} disabled={loading} type="primary">
					{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.okText')}
				</Button>
			</div>
			<Modal
				getContainer={'main-buttons'}
				centered
				closable
				onCancel={() => setWarningPopup(false)}
				footer={() => (
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Button onClick={() => setWarningPopup(false)}>
							{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.cancelText')}
						</Button>
						<Button onClick={() => changeTariffForm.submit()} disabled={loading} type="primary">
							{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.proceed')}
						</Button>
					</div>
				)}
				open={warningPopup}
				width={300}
				title={t('pages.account.ticketHoldersPage.actions.changeTariff.popup.notification')}>
				{typeId === 3 ? (
					<p>
						{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.cardWithMoney', {
							balance
						})}
					</p>
				) : (
					<p>
						{t('pages.account.ticketHoldersPage.actions.changeTariff.popup.countCard', {balance})}
					</p>
				)}
			</Modal>
		</>
	);
};
