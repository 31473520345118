import {Button, Form} from 'antd';
import {FormInstance} from 'antd/lib';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {validateMessages} from '../../../../../../Components/AuthModalForm';
import FormGroup from '../../../../../../Components/formItems/FormGroup';
import fastShift from './../../../../../../assets/fast-shift-logo.png';
import masterCard from './../../../../../../assets/icons/mastercard.svg';
import visa from './../../../../../../assets/icons/visa.svg';
import frame from './../../../../../../assets/icons/Wallet.png';
import {useMediaQuery} from 'react-responsive';
import {ModalFormFieldTypesFunc} from '../../../../../../utils/types';
import {
	numberOnlyRule,
	numberRulesAmount,
	numberRulesAmountLittle
} from '../../../../../../utils/rules/validation';
const topUpFields: ModalFormFieldTypesFunc = (t, topUpAmountMax: any) => [
	{
		name: 'amount',
		label: t('pages.account.partner.transfersHistory.totalamount1'),
		type: 'text',
		rules: [numberOnlyRule(t), numberRulesAmountLittle(t, 10), numberRulesAmount(t, topUpAmountMax)],
		style: {
			height: 68
		},
		colProps: {
			lg: 24,
			xs: 24
		}
	}
];
export const TopUpModal = ({
	onSubmitTopUp,
	topUpForm,
	formErrorsTopUp,
	paymentLoading,
	topUpAmountMax
}: {
	onSubmitTopUp: any;
	topUpForm: FormInstance<any>;
	formErrorsTopUp: string[];
	paymentLoading: boolean;
	topUpAmountMax: string;
}) => {
	let {t} = useTranslation();
	const auth = useSelector(({auth}) => auth.data);
	const isMobile = useMediaQuery({maxWidth: '768px'});

	return (
		<>
			<Form
				form={topUpForm}
				layout="vertical"
				validateTrigger="onBlur"
				validateMessages={validateMessages(t)}
				onValuesChange={() => {}}>
				<FormGroup
					tabFields={topUpFields(t, topUpAmountMax)}
					formErrors={formErrorsTopUp}
					form={topUpForm}
				/>
			</Form>
			<p>{t('pages.tickets.oneUse.popup.selectPaymentMethod')}</p>
			<div className="my-balance__payment-methods">
				{+auth.balance > 0 && (
					<div className="my-balance__payment-method first-method">
						<div className="payment-method-imgs">
							<img src={frame} alt="" />
							<span
								style={{
									fontSize: 18,
									color: '#009e84cd',
									marginLeft: 5,
									fontWeight: 600
								}}>
								{t(
									'pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byBalance'
								)}
							</span>
						</div>
						<Button
							disabled={paymentLoading}
							onClick={() => {
								onSubmitTopUp('balance');
							}}>
							{t('pages.account.myBalancePage.top.payOnline.paymentMethod.balance.title')}
						</Button>
					</div>
				)}

				<div className="my-balance__payment-method first-method">
					<div className="payment-method-imgs">
						<img src={visa} alt="" />
						<img src={masterCard} alt="" />
					</div>
					<Button
						disabled={paymentLoading}
						onClick={() => {
							onSubmitTopUp('visa');
						}}>
						{t('pages.account.myBalancePage.top.payOnline.paymentMethod.visa.title')}
					</Button>
				</div>
				<div className="my-balance__payment-method">
					<div className="payment-imgs">
						<img src={fastShift} alt="" style={{marginBottom: isMobile ? 10 : 0}} />
					</div>

					<Button
						disabled={paymentLoading}
						onClick={() => {
							onSubmitTopUp('fastshift');
						}}>
						{t('pages.account.myBalancePage.top.payOnline.paymentMethod.fastShift.title')}
					</Button>
				</div>
			</div>
		</>
	);
};
