import {Modal} from 'antd';
import {useTranslation} from 'react-i18next';

export const CreateModal = ({
	modalIndex,
	titleKey,
	className,
	content,
	onOk,
	onCancel,
	footer,
	modal,
	callPopup
}: {
	modalIndex: number;
	titleKey: string;
	className: string;
	content: React.ReactNode;
	onOk?: () => void;
	onCancel?: () => void;
	footer?: any;
	modal: any;
	callPopup: any;
}) => {
	let {t} = useTranslation();
	return (
		<Modal
			key={modalIndex}
			destroyOnClose
			centered
			footer={footer ?? null}
			closeIcon={null}
			className={`ticket-holder__popup ${className}`}
			title={t(`pages.account.ticketHoldersPage.actions.${titleKey}.popup.title`)}
			open={modal[modalIndex]}
			okText={t(`pages.account.ticketHoldersPage.actions.${titleKey}.popup.okText`)}
			onOk={onOk ?? (() => callPopup(titleKey, false))}
			onCancel={onCancel ?? (() => callPopup(titleKey, false))}>
			{content}
		</Modal>
	);
};
