import {Button, Modal, Tooltip} from 'antd';
import {useForm} from 'antd/es/form/Form';
import moment from 'moment';
import {Lock, LockOpen, Swap, Wallet} from 'phosphor-react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {rotateSvg} from '../../../../../assets/icons/compIcons/rotateSvg';
import Toast from '../../../../../Components/Toast';
import {TransportCardBack, TransportCardFront} from '../../../../../Components/TransportCard/TransportCard';
import {topUpHoldersBalance} from '../../../../../store/holdersActions.slice';
import {changHolderStatusThunk, getHoldersThunk} from '../../../../../store/myHolders.slice';
import {getBalanceString, getKeyByLang} from '../../../../../utils/heplerFuncs/functions';

import {CreateModal} from './CreateModal';
import {TopUpModal} from './TopupModal/TopupModal';
import {ChangeTariffModal} from './ChangeTariffModal/ChangeTariffModal';
import SERVICES from '../../../../../services';
import {useMediaQuery} from 'react-responsive';
import {reject} from 'lodash';

interface TicketHolderItemProps {
	options: any;
	getbalance: string;
	ticketId: any;
	setForceUpdate?: any;
	forceUpdate?: boolean;
}

const TicketHolderItem = ({options, ticketId, setForceUpdate, forceUpdate}: TicketHolderItemProps) => {
	const location = useLocation();
	const {t} = useTranslation();
	const [modal, setModal] = useState([false || location.hash === '#showBalance', false]);
	const [blockPopUp, setBlockPopUp] = useState(false);
	const dispatch = useDispatch<any>();
	const [topUpForm] = useForm();
	const [changeTariffForm] = useForm();
	const isMobile = useMediaQuery({maxWidth: 767});
	const [flip, setFlip] = useState(false);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [changeTariffLoading, setChangeTariffLoading] = useState(false);
	const [formErrorsTopUp, setFormErrorsTopUp] = useState<string[]>([]);
	const [formErrorsChangeTariff, setFormErrorsChangeTariff] = useState<string[]>([]);

	const callPopup = useCallback((type: string, open: boolean) => {
		switch (type) {
			case 'topUpBalance':
				setModal(p => {
					p[2] = open;
					return [...p];
				});
				topUpForm.resetFields();
				break;
			case 'changeTariff':
				setModal(p => {
					p[3] = open;
					return [...p];
				});
				topUpForm.resetFields();
				break;
		}
	}, []);
	// PAYMENT SUBMIT
	const onSubmitTopUp = (type: 'visa' | 'fastshift' | 'balance') => {
		topUpForm
			.validateFields()
			.then(res => {
				const amount = topUpForm.getFieldValue('amount');
				const holderNumber = options.number;
				switch (type) {
					case 'visa':
						setPaymentLoading(true);
						let payload = {
							providerId: 2,
							amount: String(amount),
							holderNumber: holderNumber.replace(/[^\d]/g, '')
						};
						dispatch(topUpHoldersBalance(payload))
							.unwrap()
							.then((response: any) => {
								if (response.data.url) {
									window.location.href = response.data.url;
								}
							})
							.catch((response: any) => {
								Toast({
									description: response.code
										? t(`messages.code.${response.code}`)
										: t(`messages.code.0`),
									type: 'error',
									duration: 2.5
								});
							})
							.finally(() => {
								setPaymentLoading(false);
							});
						break;
					case 'balance': {
						setPaymentLoading(true);

						let payload = {
							providerId: 3,
							amount: String(amount),
							holderNumber: holderNumber.replace(/[^\d]/g, '')
						};
						dispatch(topUpHoldersBalance(payload))
							.unwrap()
							.then((res: any) => {
								Toast({
									description: t(`messages.code.${res.code}`),
									type: 'success',
									duration: 2.5
								});
								setForceUpdate(!forceUpdate);
								callPopup('topUpBalance', false);
							})
							.catch((response: any) => {
								Toast({
									description: response.code
										? t(`messages.code.${response.code}`)
										: t(`messages.code.0`),
									type: 'error',
									duration: 2.5
								});
							})
							.finally(() => {
								setPaymentLoading(false);
							});
						break;
					}

					case 'fastshift':
						// dispatch({providerId:2})
						break;
				}
			})
			.catch(err => {
				const error = err.errorFields?.[0]?.name?.[0];
				if (error) {
					const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
					errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
				}
			});
	};

	const onSubmitChangeTariff = (data: any) => {
		setChangeTariffLoading(true);
		return SERVICES.HOLDERS.changeHolderTariff(data)
			.then((response: any) => {
				if (response.data.url) {
					window.location.href = response.data.url;
				} else {
					return new Promise((resolve, reject) => resolve(response));
				}
			})
			.catch(err => {
				Toast({
					description: t(`messages.code.${err.code}`),
					type: 'error',
					duration: 2.5
				});
				return new Promise((resolve, reject) => reject(err));
			})
			.finally(() => setChangeTariffLoading(false));
	};
	const handleChangesStatus = () => {
		const newStatusId = options.statusId === 1010 || options.statusId === 1030 ? 1020 : 1030;
		dispatch(changHolderStatusThunk({statusId: newStatusId, ticketId}))
			.unwrap()
			.then(() => {
				setBlockPopUp(false);
				dispatch(getHoldersThunk({}));
				Toast({type: 'success', description: t('messages.success.success')});
			})
			.catch(() => {
				Toast({type: 'error', description: t('messages.error.badRequest')});
			});
	};

	const modals = [
		CreateModal({
			modalIndex: 2,
			titleKey: 'topUpBalance',
			className: 'topUpBalance',
			content: (
				<TopUpModal
					topUpAmountMax={options?.tariff?.topUpAmountMax}
					onSubmitTopUp={onSubmitTopUp}
					formErrorsTopUp={formErrorsTopUp}
					topUpForm={topUpForm}
					paymentLoading={paymentLoading}
				/>
			),
			footer: null,
			modal,
			callPopup
		}),
		CreateModal({
			modalIndex: 3,
			titleKey: 'changeTariff',
			className: 'change-tariff',
			content: (
				<>
					<ChangeTariffModal
						balance={options.balance.slice(0, -3)}
						typeId={options?.tariff?.typeId}
						popupAction={callPopup}
						loading={changeTariffLoading}
						holderNumber={options.number}
						onSubmitChangeTariff={onSubmitChangeTariff}
						oldTariff={options?.tariff?.[getKeyByLang('name')]}
						oldTariffId={options?.tariffId}
						changeTariffForm={changeTariffForm}
						formErrorsChangeTariff={formErrorsChangeTariff}
					/>
				</>
			),
			footer: null,
			modal,
			callPopup
		})
	];

	const getCardStyle = (statusId: number) => {
		switch (statusId) {
			case 1010:
				return '';
			case 1020:
				return 'notVerificated';
			case 1030:
				return '';
			case 1040:
				return 'notVerificated';
			default:
				return '';
		}
	};
	const cardStyle = getCardStyle(options.statusId);
	const moreInfoItems = [
		{
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'),
			value:
				options.statusId === 1040 || options.statusId === 1020
					? t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.blocked')
					: t('pages.account.ticketHoldersPage.filter.status.options.activated')
		},
		{
			title: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byBalance'),
			value: getBalanceString(t, options?.balance, options?.tariff?.typeId)
		},
		{
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.registrationDate'),
			value: moment(options.createdAt).format('DD-MM-YYYY')
		},
		{
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.validDate.title'),
			value: moment(options.validDate).format('DD-MM-YYYY')
		},
		{
			title: t('pages.account.historyMarches.product'),
			value: options?.tariff ? options?.tariff?.[getKeyByLang('name')] : '-'
		}
	];
	const holderActions = [
		{
			hidden: false,
			component: (
				<div className="ticket-holder-card__reverse" style={{flex: 'unset'}}>
					<Tooltip
						trigger={isMobile ? 'contextMenu' : 'hover'}
						title={t('pages.account.ticketHoldersPage.ticketHolder.rotateCard')}>
						<div className="card-reverse__main">
							<div
								className={`card-reverse__ico ${flip && 'reversed'}`}
								onClick={() => setFlip(!flip)}>
								{rotateSvg}
							</div>
						</div>
					</Tooltip>
				</div>
			)
		},
		{
			hidden: false,
			component: (
				<div className="action-button-container" onClick={() => setBlockPopUp(true)}>
					<Tooltip
						trigger={isMobile ? 'contextMenu' : 'hover'}
						title={
							options.statusId === 1010 || options.statusId === 1030
								? t('pages.account.ticketHoldersPage.actions.blockBalance.btnTitle1')
								: t('pages.account.ticketHoldersPage.actions.blockBalance.btnTitle')
						}>
						{options.statusId === 1010 || options.statusId === 1030 ? (
							<Lock size={35} />
						) : (
							<LockOpen size={35} />
						)}
					</Tooltip>
				</div>
			)
		},

		{
			hidden:
				options?.tariff?.typeId !== 3 ||
				options.tariffId === null ||
				options.statusId === 1020 ||
				options.statusId === 1040,
			component: (
				<div className="action-button-container" onClick={() => callPopup('topUpBalance', true)}>
					<Tooltip
						title={t('pages.account.ticketHoldersPage.actions.topUpBalance.title')}
						trigger={isMobile ? 'contextMenu' : 'hover'}>
						<Wallet size={35} />
					</Tooltip>
				</div>
			)
		},
		{
			hidden: options.statusId === 1020 || options.statusId === 1040 || options.tariffId === null,
			component: (
				<div className="action-button-container" onClick={() => callPopup('changeTariff', true)}>
					<Tooltip
						title={t('pages.account.ticketHoldersPage.actions.changeTariff.title')}
						trigger={isMobile ? 'contextMenu' : 'hover'}>
						<Swap size={35} />
					</Tooltip>
				</div>
			)
		}
	];

	return (
		<div className="ticket-holder__item">
			<div className="ticket-holder__item-columns">
				{/* header and ticket card */}
				<div className="ticket-holder__item-column">
					<div className="ticket-holder__item-top">
						<div className="ticket-holder__item-header">
							<p>{t('pages.tickets.oneUse.ticketCard.storageType')}</p>
						</div>
					</div>
					<div className="ticket-holder-card__container">
						<div className={`ticket-holder-card__flip flip-box ${flip ? 'true' : ''}`}>
							<div className="ticket-holder-card__main flip-box-inner">
								<div className={`ticket-card__frontside flip-box-front ${cardStyle}`}>
									<TransportCardFront />
								</div>
								<div className={`ticket-card__backside flip-box-back ${cardStyle}`}>
									<TransportCardBack number={options.number} />
								</div>
							</div>
						</div>
						<div className="ticket-holder__item-column description">
							<div className="ticket-holder__item-description">
								{/* <div className="ticket__item-description">
									<p>
										{t('pages.account.ticketHoldersPage.ticketHolder.description.title')}
									</p>
								</div> */}
								<div className="holder-item__more-info">
									<div className="more-info__items">
										{moreInfoItems.map((elem: any, index: any) => {
											return (
												<div className="more-info__item" key={index}>
													<div className="more-info__title">{elem.title}</div>
													<div
														className="more-info__data"
														style={{display: 'flex', gap: 20}}>
														<div className="more-info__data-col">
															<p>{elem?.value}</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
									<div
										className="action-btns"
										style={{
											display: 'flex',
											gap: 20,
											justifyContent: 'flex-end',
											marginTop: 20
										}}>
										{holderActions
											.filter(elem => !elem.hidden)
											.map(elem => elem.component)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* description and other info */}

				<Modal
					destroyOnClose
					centered
					closeIcon={null}
					className={`ticket-holder__popup block-balance`}
					title={t(`pages.account.ticketHoldersPage.actions.blockBalance.popup.title`)}
					open={blockPopUp}
					okText={t(
						`pages.account.ticketHoldersPage.actions.blockBalance.popup.${
							options.statusId === 1010 || options.statusId === 1030 ? 'okText' : 'okText1'
						}`
					)}
					onOk={() => handleChangesStatus()}
					onCancel={() => setBlockPopUp(false)}>
					{t(
						`pages.account.ticketHoldersPage.actions.blockBalance.popup.${
							options.statusId === 1010 || options.statusId === 1030 ? 'subTitle' : 'subTitle1'
						}`
					)}
				</Modal>
				{modals}
			</div>
		</div>
	);
};

export default TicketHolderItem;
