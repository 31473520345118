import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import DataExport from '../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import {getUserTransactions} from '../../../../store/account.historyMarches.slice';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import i18n from '../../../../utils/translate/i18';
import './HistoryMarches.less';
import historyMarchesform from './historyMarchesform';
import {AppDispatch} from '../../../../store';
import dayjs from 'dayjs';
import {useLocation} from 'react-router-dom';
import HistoryMarchesData from './HisteoryMarchesData';
import {useMediaQuery} from 'react-responsive';
import {getKeyByLang} from '../../../../utils/heplerFuncs/functions';

const HistoryMarches = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const {pathname} = useLocation();
	const currentPageName = '/account/marchHistory';
	const auth = useSelector((state: any) => state.auth.data);
	const historyMarches = useSelector(({historyMarches}) => historyMarches.data);
	const isMobile = useMediaQuery({maxWidth: 1080});
	const [info, setInfo] = useState<any>(null);
	const [pageSize, setPageSize] = useState(10);
	const {currentPage, goToPage, currentItemsData} = usePagination(
		historyMarches?.items,
		historyMarches?.pageSize
	);
	useEffect(() => {
		let newValues: Record<string, any> = {};
		if (Object.keys(info ?? {})?.length) {
			newValues = {...info};
			if (info?.routeNumber) {
				newValues.routeNumber = info.routeNumber.split('_')[1];
			}
		}
		if (currentPageName === pathname) {
			dispatch(getUserTransactions({page: currentPage, filter: newValues, take: pageSize}));
		}
	}, [auth.id, currentPage, info, pathname, pageSize]);

	const columns = [
		{dataIndex: 'city', title: t('fields.city')},
		{dataIndex: 'routeNumber', title: t('pages.account.historyMarches.marchestitle')},
		{dataIndex: 'tariffType', title: t('pages.account.historyMarches.product')},
		{dataIndex: 'holderTariff', title: t('pages.account.paymentHistoryPage.filter.type.title')},
		{dataIndex: 'holderNumber', title: t('pages.account.historyMarches.carriertitle')},
		{dataIndex: 'amount', title: t('pages.account.partner.providedServices.filter.money')},
		{dataIndex: 'transactionDate', title: t('pages.account.partner.transfersHistory.filter.date')}
	];
	const rows = currentItemsData?.map((historyItem: any) => {
		console.log(historyItem);
		return {
			city: historyItem?.city ? historyItem?.city[i18n.language] : ' ',
			routeNumber: historyItem?.routeNumber,
			tariffType: historyItem?.[getKeyByLang('tariffName')] ?? '',
			holderTariff: historyItem?.tariffType ? historyItem?.tariffType[i18n.language] : ' ',
			holderNumber: historyItem?.holderNumber ?? ' ',
			amount: historyItem?.amount,
			transactionDate: dayjs(historyItem?.transactionDate).format('DD-MM-YYYY HH-mm').toString()
		};
	});

	return (
		<div className="history-marches">
			<div className="history-marches__container">
				<div className="history-marches__content">
					<div className="history-marches__top">
						<DataExport exportData={{rows, columns}} />
						<DefaultWrapper
							filteritems={historyMarchesform}
							defaultFilters={info}
							applyFilters={(data: any) => {
								setInfo(data);
								goToPage(1);
							}}
						/>
					</div>
					{currentItemsData && currentItemsData.length > 0 ? (
						<>
							<div className="history-marches__row">
								<div className="route-item-transport">
									<div className="route-item-transport__container">
										<div className="route-item-transport__content">
											{currentItemsData.map((elem: any) => (
												<HistoryMarchesData key={elem.id} options={elem} />
											))}
										</div>
									</div>
								</div>
							</div>
							<div style={{marginTop: isMobile ? 0 : 10}}>
								<PagePagination
									pageSizeOptions={[10, 20, 50, 100]}
									pageSize={historyMarches.pageSize}
									currentPage={historyMarches.currentPage}
									totalItems={historyMarches.totalCount}
									onPageChange={goToPage}
									onTakeChange={size => {
										setPageSize(size);
										goToPage(1);
									}}
								/>
							</div>
						</>
					) : (
						<div style={{padding: '50px 0'}}>
							<Empty />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default HistoryMarches;
