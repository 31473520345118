import {Empty} from 'antd';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import DataExport from '../../../../Components/DataExport/DataExport';
import {getPaymentHistoryThunk} from '../../../../store/account.paymentHistory';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import './PaymentHistory.less';

import dayjs from 'dayjs';
import {useLocation} from 'react-router-dom';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import filteritems from './filter_fields.form';
import PaymentHistoryItem from './PaymentHistoryItem';
import i18n from '../../../../utils/translate/i18';
import moment from 'moment';

const PaymentHistory = () => {
	let {t} = useTranslation();
	const currentPageName = '/account/paymentHistory';
	const {pathname} = useLocation();
	const dispatch = useDispatch();
	const paymentHistory = useSelector(({paymentHistory}) => paymentHistory.paymentHistory);
	const {currentPage, goToPage, currentItemsData} = usePagination(
		paymentHistory?.items,
		paymentHistory?.pageSize
	);
	const [pageSize, setPageSize] = useState(4);

	const [info, setInfo] = useState(null);

	useEffect(() => {
		if (currentPageName === pathname) {
			dispatch(getPaymentHistoryThunk({filter: info, page: currentPage, take: pageSize}));
		}
	}, [info, currentPage, pathname, pageSize]);
	const columns = [
		{dataIndex: 'date', title: t('pages.account.partner.transactions.timePeriod')},
		{dataIndex: 'amount', title: t('pages.account.paymentHistoryPage.ticketItem.ticketAbout.cost')},
		{dataIndex: 'serviceType', title: t('pages.account.paymentHistoryPage.serviceType')},
		{
			dataIndex: 'paymentMethod',
			title: t('pages.account.paymentHistoryPage.ticketItem.ticketAbout.paymentMethod')
		},
		{
			dataIndex: 'status',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title')
		}
		// {dataIndex: 'pan', title: t('pages.account.paymentHistoryPage.outgoingAccount')}
	];
	const rows = currentItemsData?.map((historyPayment: any) => {
		return {
			paymentType: historyPayment.routeNumber,
			amount: historyPayment.amount,
			serviceType: historyPayment.type[i18n.language] ?? 'Tariff name',
			paymentMethod: historyPayment.provider[`${i18n.language}`] ?? 'Payment Method',
			status: historyPayment.status[i18n.language],
			// pan: '*PAN code*',
			date: moment(historyPayment.createdAt).format('DD-MM-YYYY HH:mm')
		};
	});
	return (
		<div className="payment-history">
			<div className="payment-history__container">
				<div className="payment-history__content">
					<div className="payment-history__rows">
						<div className="payment-history__top">
							<DataExport exportData={{rows, columns}} />
							<DefaultWrapper
								filteritems={filteritems}
								defaultFilters={info}
								applyFilters={(data: any) => {
									goToPage(1);
									setInfo(data);
								}}
							/>
						</div>
						<div style={{display: 'flex', gap: 22, flexDirection: 'column'}}>
							{currentItemsData && currentItemsData.length > 0 ? (
								currentItemsData?.map((payment: any, index) => (
									<PaymentHistoryItem payment={payment} key={index} />
								))
							) : (
								<div style={{padding: '50px 0px'}}>
									<Empty />
								</div>
							)}
						</div>
					</div>
					{currentItemsData && currentItemsData.length > 0 && (
						<PagePagination
							pageSizeOptions={[4, 10, 20, 50, 100]}
							pageSize={paymentHistory.pageSize}
							currentPage={paymentHistory.currentPage}
							totalItems={paymentHistory.totalCount}
							onPageChange={goToPage}
							onTakeChange={size => {
								setPageSize(size);
								goToPage(1);
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default PaymentHistory;
